<template>
  <v-list-item>
    <template v-if="miniMenu">
      <v-list-item-action>
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-avatar :color="$randomizeColorFromString(selectedTenant, 75, 70)" class="organization-avatar" size="34" v-on="on">
              <span class="text-uppercase font-weight-black white--text">{{ organizationLetters }}</span>
            </v-avatar>
          </template>
          <v-list class="pt-0 pb-0" dense>
            <v-subheader>{{ $t('tenants.choose_organization') }}:</v-subheader>
            <v-list-item v-for="tenant in possibleTenants" :key="tenant.id" @click="selectTenant(tenant)">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">{{ tenant.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </template>
    <template v-else>
      <v-select
        v-model="selectedTenant"
        :items="possibleTenants"
        :label="$t('tenants.organization')"
        class="mt-3 mb-1"
        hide-details
        item-text="name"
        item-value="id"
        outlined
        @change="tenantSelected"
      ></v-select>
    </template>
  </v-list-item>
</template>

<script>
import tenantService from '../service/tenant.service';

export default {
  name: 'TenantSelect',
  props: {
    miniMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTenant: null,
    };
  },
  methods: {
    selectTenant(tenant) {
      this.selectedTenant = tenant.id;
      this.tenantSelected();
    },
    tenantSelected() {
      if ('tenantId' in this.$router.currentRoute.params) {
        const currentParams = this.$router.currentRoute.params;
        currentParams.tenantId = this.selectedTenant;
        this.$router.push({
          name: 'tenant_questionnaires',
          //params: currentParams,
        });
      }
      tenantService.setTenant(this.selectedTenant);
      location.reload();
    },
  },
  computed: {
    possibleTenants() {
      return this.$userInfo.tenants;
    },
    organizationLetters() {
      const orgIndex = this.possibleTenants.findIndex(tenant => tenant.id === this.selectedTenant);
      if (orgIndex > -1) {
        return this.possibleTenants[orgIndex].name.substring(0, 2);
      }
      return '';
    },
  },
  mounted() {
    this.selectedTenant = tenantService.tenant;
  },
};
</script>

<style lang="scss" scoped>
.organization-avatar {
  margin: 0;
}
</style>
