<template>
  <hb-basic-page :back-button="{ show: true, fallback: 'tenant_questionnaires' }" :loading="loading" :title="$t('questionnaire._')">
    <v-col>
      <v-row>
        <v-col cols="12">
          <v-card elevation="2">
            <v-card-actions>
              <v-row>
                <v-col class="pa-3" xs="12">
                  <v-dialog v-model="importDialog" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined rounded small v-bind="attrs" v-on="on">
                        {{ $t("questionnaire.import.link") }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>{{ $t("questionnaire.import.title") }}</v-card-title>
                      <v-card-text>
                        <!--<v-textarea
                          v-model="importCSVText"
                          rows="10"
                          placeholder="AREA	PARENT DIMENSION	ORDER	QUESTION	DIMENSION	DEMOGRAPHIES	DEMOGRAPHY_TYPE	ANSWERTYPE
            1.0.1.	Sex		Male,Female	sex	DEMOGRAPHY
        DO RIGHT	STRUCTURE	1.1.1.	.. the organisation has a clear set of objectives to achieve its strategy	PLAN			OPINION_SCALE,0-6,Cannot assess,Strongly disagree,Disagree,Disagree moderately,Slightly agree,Moderately agree,Strongly agree"
                        >
                        </v-textarea>-->
                        <v-file-input
                          v-model="questionsCSVFile"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          label="XLSX file"
                        />

                        <v-textarea v-if="importCSVText" v-model="importCSVText" rows="10"></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="error" outlined rounded small @click="importDialog = false">
                          {{ $t("close") }}
                        </v-btn>
                        <v-spacer />
                        <v-btn :loading="importDialogLoading" color="primary" outlined rounded small @click="addQuestionsFromCsv">
                          {{ $t("import") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="importAnswerersDialog" max-width="900">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-3" outlined rounded small v-bind="attrs" v-on="on">
                        {{ $t("questionnaire.import.answerers_link") }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title>{{ $t("questionnaire.import.answerers_title") }}</v-card-title>
                      <v-card-text>
                        <v-textarea v-model="importAnswerersCSVText" placeholder="FULL NAME	EMAIL" rows="10"></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="error" outlined rounded @click="importAnswerersDialog = false">
                          {{ $t("close") }}
                        </v-btn>
                        <v-spacer />
                        <v-btn :loading="importDialogLoading" color="primary" outlined rounded @click="addAnswerersFromCsv">
                          {{ $t("import") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-divider />

            <v-card-title
              >{{ questionnaire[`name${lang.value}`] }} ({{ questionnaire[`lang${lang.value}Name`] }})
              <v-select v-model="lang" :items="langs" dense hide-details return-object rounded style="width: 250px; max-width: 250px">
                <template v-slot:prepend-inner>
                  <div class="d-flex justify-center align-center mt-1">
                    <v-icon small>mdi mdi-translate</v-icon>
                  </div>
                </template>
                <template v-slot:item="{ parent, item }">
                  <span>{{ item.label }}</span>
                  <small v-if="!questionnaire[`name${item.value}`]" class="error--text ml-2">(not enabled)</small>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.label }}

                  <small v-if="!questionnaire[`name${item.value}`]" class="error--text ml-2">(not enabled)</small>
                </template>
              </v-select>
              <!--<small v-if="languageVersionsEnabled" class="success--text">Language versions enabled</small>
              <v-tooltip v-else-if="!languageVersionsEnabled" top>
                <template v-slot:activator="{ on, attrs }">
                  <small class="error--text" v-bind="attrs" v-on="on">Language versions NOT enabled</small>
                </template>
                <span
                  >English, Swedish and Finnish questionnaire name fields must be filled. Make sure you have uploaded a translated version
                  of questionnaire too.</span
                >
              </v-tooltip>-->
            </v-card-title>
            <v-card-subtitle v-if="questionnaire[`description${lang.value}`]">
              {{ questionnaire[`description${lang.value}`] || "" }}
            </v-card-subtitle>

            <v-card-actions v-if="!edit" class="flex-wrap">
              <v-btn :disabled="edit" outlined rounded small @click="toggleEdit">
                <v-icon>mdi mdi-pencil</v-icon>
                Edit
              </v-btn>
              <v-btn outlined rounded small title="Go to answers" @click="goToAnswers">
                <v-icon>mdi mdi-forum</v-icon>
                Answers data
              </v-btn>
              <v-btn
                :loading="loadingDownload"
                :title="$t('questionnaire.download_report')"
                outlined
                rounded
                small
                @click="downloadAnswerReport"
              >
                <v-icon>mdi mdi-file-table</v-icon>
                CSV
              </v-btn>
              <v-btn
                :loading="loadingDownload"
                :title="$t('questionnaire.download_report_include_own_answers')"
                outlined
                rounded
                small
                @click="downloadAnswerReportIncludeTestAnswers"
              >
                <v-icon>mdi mdi-account-alert</v-icon>
                CSV including testers
              </v-btn>
            </v-card-actions>
            <v-card-actions v-if="edit" class="flex-wrap">
              <v-btn color="default" outlined rounded small @click="edit = false"> Cancel</v-btn>

              <v-btn :loading="loadingDeleteQuestions" class="ml-4" color="error" outlined rounded small @click="destroyQuestions">
                <v-icon>mdi mdi-trash-can-outline</v-icon>
                Delete questions for reimport
              </v-btn>
              <v-btn :loading="loadingSave" class="ml-4" color="success" outlined rounded small @click="save">
                <v-icon>mdi mdi-content-save-outline</v-icon>
                Save
              </v-btn>
            </v-card-actions>
            <v-divider />
            <v-divider v-if="edit" />
            <v-card-text v-if="edit">
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-card-title v-if="edit">Evaluation</v-card-title>
                    <v-col lg="12">
                      <v-select v-model="questionnaire.type" :items="types" hide-details label="Evaluation type" outlined></v-select>
                    </v-col>
                    <v-col lg="12">
                      <v-text-field
                        v-model="questionnaire[`name${lang.value}`]"
                        :label="`Name (${questionnaire[`lang${lang.value}Name`]})`"
                        :placeholder="`Name (${questionnaire[`lang${lang.value}Name`]})`"
                        outlined
                      />
                      <v-text-field
                        v-model="questionnaire[`lang${lang.value}Name`]"
                        :label="`Language name (${questionnaire[`lang${lang.value}Name`]})`"
                        :placeholder="lang.label"
                        outlined
                      />
                      <v-textarea
                        v-model="questionnaire[`description${lang.value}`]"
                        :label="`Description (${questionnaire[`lang${lang.value}Name`]})`"
                        :placeholder="`Description (${questionnaire[`lang${lang.value}Name`]})`"
                        outlined
                        rows="5"
                      />
                    </v-col>
                    <v-col lg="12">
                      <v-text-field
                        v-model="questionnaire[`startButtonText${lang.value}`]"
                        :label="`Start Button Text (${questionnaire[`lang${lang.value}Name`]})`"
                        :placeholder="`Start Button Text (${questionnaire[`lang${lang.value}Name`]})`"
                        outlined
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-card-title v-if="edit">Email</v-card-title>
                    <v-col lg="12">
                      <v-text-field
                        v-model="questionnaire.emailSubject"
                        :readonly="!edit"
                        :rules="[value => (value || '').length <= 1024 || 'Max 1024 characters']"
                        label="Email subject"
                        outlined
                        placeholder="Email subject"
                      />
                      <v-textarea
                        v-model="questionnaire.emailTemplate"
                        :readonly="!edit"
                        label="Email template"
                        outlined
                        placeholder="Email template"
                        rows="5"
                      />
                      <v-alert color="warning"><code v-html="'{{URL}}'"></code> will be changed to answerer's corresponding url. </v-alert>
                    </v-col>

                    <v-divider />
                    <v-col lg="12">
                      <v-text-field
                        v-model="questionnaire.reminderEmailSubject"
                        :readonly="!edit"
                        :rules="[value => (value || '').length <= 1024 || 'Max 1024 characters']"
                        label="Reminder email subject"
                        outlined
                        placeholder="Email subject"
                      />
                      <v-textarea
                        v-model="questionnaire.reminderEmailTemplate"
                        :readonly="!edit"
                        label="Reminder email template"
                        outlined
                        placeholder="Email template"
                        rows="5"
                      />
                      <v-alert color="warning"><code v-html="'{{URL}}'"></code> will be changed to answerer's corresponding url. </v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="['360', 'Personality', 'Motivation'].includes(questionnaire.type)" class="mt-5" elevation="2">
            <v-card-title>Share link for report</v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col v-if="['Personality', 'Motivation'].includes(questionnaire.type)" cols="12" md="5">
                  <v-autocomplete
                    v-model="shareLinkSelectedAnswerers"
                    :item-text="item => `${item.answerer.firstname} ${item.answerer.lastname} (${item.answerer.email})`"
                    :items="questionnaire.answererQuestionnaires"
                    clearable
                    dense
                    hide-details
                    label="Answerers"
                    multiple
                    outlined
                    persistent-placeholder
                    placeholder="All answerers"
                    return-object
                    rounded
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn
                    :loading="loadingShareLink"
                    :title="$t('questionnaire.share_report_link')"
                    outlined
                    rounded
                    small
                    @click="getShareLink"
                  >
                    <v-icon>mdi mdi-share</v-icon>
                    {{ $t("questionnaire.share_report_link") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />

            <v-card-text v-if="shareLink">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    :label="$t('questionnaire.share_report_link')"
                    :value="shareLinkUrl"
                    hint="Generating a PDF file might take multiple minutes. Please be patient."
                    persistent-hint
                    readonly
                  >
                    <template v-slot:append-outer>
                      <v-btn class="mr-2" outlined rounded small @click="copyShareLinkToClipboard">
                        <v-icon>mdi mdi-clipboard-outline</v-icon>
                      </v-btn>
                      <v-btn :loading="loadingShareLinkPdf" outlined rounded small @click="downloadShareLinkPdf">
                        <v-icon>mdi mdi-file-pdf</v-icon>
                        PDF
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col v-if="['Personality', 'Motivation'].includes(questionnaire.type)" cols="12" lg="6">
                  <v-text-field
                    :label="$t('questionnaire.share_report_link2')"
                    :value="shareLink2Url"
                    hint="Generating a PDF file might take multiple minutes. Please be patient."
                    persistent-hint
                    readonly
                  >
                    <template v-slot:append-outer>
                      <v-btn class="mr-2" outlined rounded small @click="copyShareLink2ToClipboard">
                        <v-icon>mdi mdi-clipboard-outline</v-icon>
                      </v-btn>
                      <v-btn :loading="loadingShareLink2Pdf" outlined rounded small @click="downloadShareLink2Pdf">
                        <v-icon>mdi mdi-file-pdf</v-icon>
                        PDF
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col v-if="shareLink && shareLink.answerers" cols="12">
                  <div class="text-subtitle-1">Answerers in share link:</div>
                  <ul class="mt-3">
                    <li v-for="answerer in shareLink.answerers" :key="answerer.id">
                      <strong>{{ answerer.firstname }} {{ answerer.lastname }} ({{ answerer.email }})</strong>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-5" elevation="2">
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>{{ $t("question.code") }}</th>
                      <th>{{ $t("question._") }}</th>
                      <th>{{ $t("area._") }}</th>
                      <!--<th>{{ $t('dimension.parent') }}</th>-->
                      <th>{{ $t("dimension._") }}</th>
                      <th>{{ $t("question.answerType") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <admin-question-row v-for="item in questionnaire.questions" :key="item.id" :item="item" :lang="lang.value" />
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card elevation="2">
            <v-card-title> {{ $t("answerer._s") }}</v-card-title>
            <v-divider />
            <v-card-actions>
              <v-btn outlined rounded small @click="openSendEmailDialog()">
                <v-icon>mdi mdi-email-send</v-icon>
                Send
              </v-btn>
              <v-btn outlined rounded small @click="openSendReminderEmailDialog()">
                <v-icon>mdi mdi-email-send</v-icon>
                Send reminders
              </v-btn>
              <v-btn outlined rounded small @click="openSentEmails">
                <v-icon>mdi mdi-format-list-text</v-icon>
                Sent emails
              </v-btn>
            </v-card-actions>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <!--<th>{{ $t('answerer.uuid') }}</th>-->

                    <th>{{ $t("answerer.name") }}</th>
                    <th>{{ $t("answerer.email") }}</th>
                    <th>{{ $t("answerer.link") }}</th>
                    <th>{{ $t("answerer.send_link") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="aq in questionnaire.answererQuestionnaires" :key="aq.id">
                    <!--<td>{{ aq.uuid }}</td>-->

                    <td>{{ aq.answerer.firstname }} {{ aq.answerer.lastname }}</td>
                    <td>{{ aq.answerer.email }}</td>
                    <td>{{ getUUIDLink(aq.uuid) }}</td>
                    <td>
                      <v-btn icon small @click="openSendEmailDialog([aq.answerer.email])">
                        <v-icon>mdi mdi-email-send</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="importSuccessful">
        {{ $t("message.import.successful") }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="importSuccessful = false"> Close</v-btn>
        </template>
      </v-snackbar>
      <sent-emails v-model="sentEmailsDialog" :loading-sent-emails="loadingSentEmails" :sent-emails="sentEmails" />
      <send-emails
        v-model="sendEmailsDialog"
        :email-recipients="emailRecipients"
        :questionnaire-id="questionnaire.id"
        :sent-emails="sentEmails"
        :to-send-email-addresses="toSendEmailAddresses"
        @sent="openSentEmails()"
      />
      <send-reminder-emails
        v-model="sendReminderEmailsDialog"
        :email-recipients="emailRecipients"
        :questionnaire-id="questionnaire.id"
        :sent-emails="sentEmails"
        emails
        @sent="openSentEmails()"
      />
    </v-col>
  </hb-basic-page>
  <!--<v-container fluid :fill-height="loading" class="report-view" v-if="$isTenantAdmin">

    <v-app-bar :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'" dark fixed app
               :elevate-on-scroll="$vuetify.breakpoint.smAndDown">

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-toolbar-title style="margin-left: 0; width: 100%;">
          {{ $t('questionnaire._') }}
        </v-toolbar-title>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>



  </v-container>-->
</template>

<script>
import filenameValidator from "@/plugins/filenamevalidator.plugin";
import download from "downloadjs";
import questionnaireApi from "@/api/questionnaire";
import emailApi from "@/api/email";
import moment from "moment";
import AdminQuestionRow from "@/components/Questionnaire/AdminQuestionRow";
import SentEmails from "@/views/tenant/SentEmails.vue";
import SendEmails from "@/views/tenant/SendEmails.vue";
import SendReminderEmails from "@/views/tenant/SendReminderEmails.vue";
import questionnaireLangs from "@/plugins/questionnairelangs";

export default {
  name: "QuestionnaireView",
  components: { SendReminderEmails, SendEmails, SentEmails, AdminQuestionRow },
  data() {
    return {
      loading: true,
      loadingShareLink: false,
      loadingShareLink2: false,
      loadingShareLinkPdf: false,
      loadingShareLink2Pdf: false,
      importDialog: false,
      importDialogLoading: false,
      importCSVText: "",
      importSuccessful: false,

      questionnaire: {
        nameFi: null,
        nameSv: null,
        nameEn: "New questionnaire",
        descriptionFi: null,
        descriptionSv: null,
        descriptionEn: null,
        orderer: null,
      },
      importAnswerersDialog: false,
      importAnswerersCSVText: "",
      loadingDownload: false,
      loadingSave: false,
      loadingDeleteQuestions: false,
      edit: false,
      questionsCSVFile: null,
      sentEmailsDialog: false,
      loadingSentEmails: false,
      shareLink: null,
      shareLink2: null,
      sentEmails: [],
      sendEmailsDialog: false,
      sendReminderEmailsDialog: false,
      toSendEmailAddresses: [],
      sendEmailsWithoutUrl: false,
      lang: {
        value: "En",
        flag: "us",
        label: "English",
      },
      shareLinkSelectedAnswerers: [],
    };
  },
  methods: {
    async copyShareLinkToClipboard() {
      try {
        await navigator.clipboard.writeText(this.shareLinkUrl);
        this.$showSuccessNotification("Copied to clipboard");
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    async copyShareLink2ToClipboard() {
      try {
        await navigator.clipboard.writeText(this.shareLink2Url);
        this.$showSuccessNotification("Copied to clipboard");
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    async openSentEmails() {
      this.sentEmailsDialog = true;
      this.loadingSentEmails = true;
      this.sentEmails = await emailApi.getSentEmails(this.questionnaire.id);
      this.loadingSentEmails = false;
    },
    async openSendEmailDialog(emails = []) {
      this.sendEmailsDialog = true;
      this.toSendEmailAddresses = emails;
    },
    async openSendReminderEmailDialog(/*emails = []*/) {
      this.sendReminderEmailsDialog = true;
    },

    async downloadShareLinkPdf() {
      this.loadingShareLinkPdf = true;
      try {
        //const tenant = await this.$tenantApi.getCurrentTenant();
        const response = await questionnaireApi.shareLinkReportPdf(this.shareLinkUrl + `&pdf=1`);
        console.log(response, response.url);
        window.open(response.url);
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
      this.loadingShareLinkPdf = false;
    },
    async downloadShareLink2Pdf() {
      this.loadingShareLink2Pdf = true;
      try {
        //const tenant = await this.$tenantApi.getCurrentTenant();
        const response = await questionnaireApi.shareLinkReportPdf(this.shareLink2Url + `&pdf=1`);
        console.log(response, response.url);
        window.open(response.url);
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }
      this.loadingShareLink2Pdf = false;
    },
    async getQuestionnaire() {
      this.loading = true;
      this.questionnaire = await questionnaireApi.getQuestionnaire(this.$route.params.id);
      this.loading = false;
    },
    toggleEdit() {
      this.edit = !this.edit;
    },
    goToAnswers() {
      this.$router.push({ name: "tenant_questionnaire_answers" });
    },
    async destroyQuestions() {
      const c = await this.$confirmDelete("Do you really want to delete ALL questions from this questionnaire?");
      if (c) {
        this.loadingDeleteQuestions = true;
        await questionnaireApi.deleteQuestions(this.questionnaire.id);
        this.loadingDeleteQuestions = false;
        this.reloadAll();
      }
    },
    async save() {
      this.loadingSave = true;
      await questionnaireApi.upsertQuestionnaire(this.questionnaire);
      this.loadingSave = false;
      this.edit = false;
    },
    async reloadAll() {
      this.getQuestionnaire(this.$route.params.id);
    },
    async addQuestionsFromCsv() {
      if (this.questionsCSVFile.name?.split(".").pop() === "xlsx") {
        this.addQuestionsFromXlsx();
        return;
      }
      this.importDialogLoading = true;
      try {
        await questionnaireApi.addQuestionsFromCsv(this.$route.params.id, this.importCSVText);
      } catch (ex) {
        this.$showErrorNotification(ex);
        console.error(ex);
      }
      this.importDialogLoading = false;
      this.importCSVText = "";
      this.questionsCSVFile = null;
      this.importDialog = false;

      this.importSuccessful = true;
      this.reloadAll();
    },
    async addQuestionsFromXlsx() {
      this.importDialogLoading = true;
      try {
        await questionnaireApi.addQuestionsFromXlsx(this.$route.params.id, this.questionsCSVFile);
        this.importSuccessful = true;
      } catch (ex) {
        this.$showErrorNotification(ex.response?.data?.message);
        console.error(ex, ex.response?.data);
      }
      this.importDialogLoading = false;
      this.importCSVText = "";
      this.questionsCSVFile = null;
      this.importDialog = false;

      this.reloadAll();
    },
    async addAnswerersFromCsv() {
      this.importDialogLoading = true;
      try {
        await questionnaireApi.addAnswerersFromCsv(this.$route.params.id, this.importAnswerersCSVText);
        this.importSuccessful = true;
      } catch (ex) {
        this.$showErrorNotification(ex);
        console.error(ex);
      }
      this.importDialogLoading = false;
      this.importAnswerersCSVText = "";
      this.importAnswerersDialog = false;

      this.reloadAll();
    },
    async getShareLink() {
      this.loadingShareLink = true;
      this.shareLink = null;
      try {
        const shareLink = await questionnaireApi.getQuestionnaireShareLink(
          this.$route.params.id,
          this.shareLinkSelectedAnswerers.map(a => a.answerer?.id).filter(Boolean)
        );
        this.shareLink = shareLink;
      } catch (e) {
        console.error(e);
        this.$handleApiError(e);
      }

      this.loadingShareLink = false;
    },
    async downloadAnswerReport() {
      this.loadingDownload = true;
      const csv = await questionnaireApi.downloadAnswerReport(this.$route.params.id, this.$store.state.testers);
      const tenant = await this.$tenantApi.getCurrentTenant();

      download(
        csv,
        filenameValidator([tenant.name, this.questionnaire.nameEn, moment().format("YYYY-MM-DD")].filter(Boolean).join("_").concat(".csv"))
      );
      this.loadingDownload = false;
    },
    async downloadAnswerReportIncludeTestAnswers() {
      this.loadingDownload = true;
      const csv = await questionnaireApi.downloadAnswerReportIncludeTestAnswers(this.$route.params.id);
      const tenant = await this.$tenantApi.getCurrentTenant();

      download(
        csv,
        filenameValidator([tenant.name, this.questionnaire.nameEn, moment().format("YYYY-MM-DD")].filter(Boolean).join("_").concat(".csv"))
      );
      this.loadingDownload = false;
    },
    getUUIDLink(uuid) {
      return `${this.linkBaseUrl}/${uuid}`;
    },
  },
  computed: {
    types() {
      return this.$store.state.questionnaireTypes;
    },
    langs() {
      return questionnaireLangs(this.questionnaire);
    },

    linkBaseUrl() {
      return `${document.location.origin}/public/#/tenant/${this.$tenant}/questionnaire`;
    },
    shareLinkUrl() {
      let type = "";
      switch (this.questionnaire.type) {
        case "360":
          type = "360report";
          break;
        case "Personality":
          type = "personality-report";
          break;
        case "Motivation":
          type = "motivation-report";
          break;
      }
      if (!this.shareLink) return null;
      let slink = `${document.location.origin}/public/#/tenant/${this.$tenant}/${type}/${this.shareLink.questionnaireId}?token=${this.shareLink.token}&lang=${this.lang.value}`;
      if (this.shareLink.answerers?.length > 0) {
        slink += `&answerers=${this.shareLink.answerers.map(a => encodeURIComponent(a.email)).join(",")}`;
      }

      return slink;
    },
    shareLink2Url() {
      let type = "";
      switch (this.questionnaire.type) {
        case "Personality":
          type = "personality-report-2";
          break;
        case "Motivation":
          type = "motivation-report-2";
          break;
      }
      if (!this.shareLink) return null;
      let slink = `${document.location.origin}/public/#/tenant/${this.$tenant}/${type}/${this.shareLink.questionnaireId}?token=${this.shareLink.token}&lang=${this.lang.value}`;
      if (this.shareLink.answerers?.length > 0) {
        slink += `&answerers=${this.shareLink.answerers.map(a => encodeURIComponent(a.email)).join(",")}`;
      }

      return slink;
    },
    emailRecipients() {
      const emails =
        this.toSendEmailAddresses.length > 0
          ? this.toSendEmailAddresses
          : this.questionnaire?.answererQuestionnaires?.map(a => a.answerer?.email || "");

      return {
        string: emails?.filter(Boolean)?.join(";") || "",
        count: emails?.length || 0,
        emails: emails?.filter(Boolean) || [],
      };
    },

    languageVersionsEnabled() {
      return this.questionnaire.nameEn && (this.questionnaire.nameSv || this.questionnaire.nameFi);
    },
  },
  mounted() {
    this.reloadAll();
  },
  watch: {
    shareLinkSelectedAnswerers() {
      this.shareLink = null;
    },
    questionsCSVFile(newValue) {
      if (newValue !== null && newValue?.constructor?.name === "File") {
        let fr = new FileReader();
        fr.onload = event => {
          if (this.questionsCSVFile?.name.split(".").pop() !== "xlsx") this.importCSVText = event.target.result;
        };
        fr.readAsText(newValue);
        return;
      }

      this.importCSVText = null;
      return;
    },
  },
};
</script>

<style scoped>
tr.trow:hover {
  background-color: #616161 !important;
}
</style>
